body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: "white";
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */


.app-watermark{
        align-self: center;
        bottom: 0;
        /* right: 0; */
        padding: 2px;
}
#root{
        overflow-x: hidden;
        height: 100%;
}
.app-watermark > p{
        margin:0;
        color: rgba(14, 14, 14, 0.308);
}
      /* width */
      ::-webkit-scrollbar {
                width: 5px;
        }
      
      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1; 
      }
       
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: rgba(161, 161, 161, 0.781); 
      }
      
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555; 
      }

/* @supports(padding: max(0px)) {
        #root {
            padding-left: max(12px, env(safe-area-inset-left));
            padding-right: max(12px, env(safe-area-inset-right));
        }
    } */

@media only screen and (max-width: 600px) {
	.app-watermark{
        align-self: center;
        position: static;
        position: initial;
        }
        .dashboard-footer{
                width:100%;
                position: relative !important;
        }
        ::-webkit-scrollbar {
                width: 0px;
              }
}




/*Animation Keyframes*/ /* Firefox */
@-webkit-keyframes blink {0%{opacity:1;} 50%{opacity:0.5;} 100%{opacity:1;}} /* Webkit */ /* IE */
@keyframes blink {0%{opacity:1;} 50%{opacity:0.5;} 100%{opacity:1;}} /* Opera */

.login-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	flex-direction: column;
}
.login-error{
	color: #ff4b4b;
	position: absolute;
	text-align: center;
	left: 0;
	width: 100%;
	font-size: 13px;
}
/* .login-rememberme{
	margin-top: 27px;
} */
.login-container-main{
	flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px 10px;
}
.login-container-main .logo{
	width: 150px;
	height: 150px;
	padding: 16px;
}
.login-container-main .logo > img {
	width: 100%;
	border-radius: 50%;
}
.login-form {
	max-width: 400px;
	padding: 1rem;
	width: 100%;
}
.login-form .label-small{
	font-size : 10px;
}
.login-form input {
	font-size: 15px;
	border: none;
	outline: 0;
}
.login-btn{
	width: 20px;
    cursor: pointer;
    margin-top: 4px;
}
 .right-arrow{
	-webkit-filter:opacity(0.6);
	        filter:opacity(0.6);
}
 .right-arrow:hover{
	-webkit-filter: opacity(0.9);
	        filter: opacity(0.9)
}
.ant-input-affix-wrapper:hover{
	border: none;
	outline: none ;
}

.login-form .ant-input:focus{
	border: none;
	outline: none;
	box-shadow: none;
}

.login-form .login-form-button {
	width: 100%;
	height: 42px;
	font-size: 18px;
}
.login-footer {
	width: 100%;
	height: 100px;
	/* position: fixed;
	bottom: 0; */
	display: flex;
	align-items: center;
	justify-content:center;
	box-sizing: border-box;
	padding: 10px 0px 0px 0px;
    flex-direction: column;
}
.login-footer p{
	margin: 0;
}
.login-footer a{
	margin-left: 8px;
	margin-right: 8px;	
}
.login-footer .copyright{
	font-size: 10px;
}
.login-form .login-input-group{
	box-sizing: border-box;
    padding: 5px;
    border: 1px solid #cecece;
    border-radius: 7px;
}
.login-form .ant-form-item{
	margin: 0;
}
.login-form .login-checkbox {
	display: flex;
	justify-content: center;
	margin-top: 27px;
}
.login-form .login-divider {
	width: 80%;
	min-width: 80%;
	margin: 0 auto;
}
.login-form .login-divider-m0{
	margin: 0;
}
.login-form label {
	font-size: 17px;
}
.login-form .login-actions {
	margin-top: 10px;
	text-align: center;
}
.login-form .login-actions a,
p {
	line-height: 30px;
	font-size: 14px;
}
.login-title {
	font-size: 20px;
    /* font-weight: 500; */
    margin: 0 0 0 0;
    color: black;
}
.anticon-right-circle {
	cursor: pointer;
	color: #8e8e8e;
	transition: color 0.3s;
	font-size: 1.7rem;
}
.anticon-right-circle:hover {
	color: rgb(92, 92, 92);
}
.anticon-right-circle:active {
	color: rgb(92, 92, 92);
}
.ant-form-explain-holder{
	display: none;
}
@media only screen and (max-width: 600px) {
	
	.login-title {
		font-size: 20px;
	}
	.login-form input {
		font-size: 14px;
	}
}
@media only screen and (max-height: 500px) {
	.login-footer-container{
		display: none;
	}
	
}

/*Register actions*/
.register-actions {
	text-align: center;
}
.register-actions>a {
	margin-left: 4px;
}
.openInAppSliver {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 10000;
    color: white !important;
    text-decoration: none;
    background-color: #1a8ffd;
    height: 6vh;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    /* This timing applies on the way IN */
    transition-timing-function: ease-out;

    /* A litttttle slower on the way in */
    transition: 0.25s;

    /* Move into place */
    -webkit-transform: translateY(0);
            transform: translateY(0);
}

.openInAppSliver a {
    color: #ffffff;
}

.openInAppSliver a:visited {
    color: #ffffff;
    font-weight: bold;
}

.appLinkContainer {
    position: relative;
    width: 100%;
    text-align: center;
}

.close {
    position: absolute;
    right: 32px;
    width: 32px;
    height: 32px;
    opacity: 1;
    color: #fff;
}
.close:hover {
    opacity: 1;
}
.close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 15px;
    width: 2px;
    background-color: #fff;
}
.close:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
.close:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.registration-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.registration-error {
    color: #ff4b4b;
    position: absolute;
    text-align: center;
    left: 0;
    width: 100%;
    font-size: 13px;
}

/* .registration-rememberme{
	margin-top: 27px;
} */
.registration-container-main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px 10px;
}

.registration-container-main .logo {
    width: 150px;
    height: 150px;
    padding: 16px;
}

.registration-container-main .logo > img {
    width: 100%;
    border-radius: 50%;
}

.registration-form {
    max-width: 400px;
    padding: 1rem;
    width: 100%;
}

.registration-form .label-small {
    font-size: 10px;
}

.registration-form input {
    font-size: 15px;
    border: none;
    outline: 0;
}

.registration-btn {
    width: 20px;
    cursor: pointer;
    margin-top: 4px;
}

.right-arrow {
    -webkit-filter: opacity(0.6);
            filter: opacity(0.6);
}

.right-arrow:hover {
    -webkit-filter: opacity(0.9);
            filter: opacity(0.9)
}

.ant-input-affix-wrapper:hover {
    border: none;
    outline: none;
}

.registration-form .ant-input:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

.registration-form .registration-form-button {
    width: 100%;
    height: 42px;
    font-size: 18px;
}

.registration-footer {
    width: 100%;
    height: 100px;
    /* position: fixed;
    bottom: 0; */
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 10px 0px 0px 0px;
    flex-direction: column;
}

.registration-footer p {
    margin: 0;
}

.registration-footer a {
    margin-left: 8px;
    margin-right: 8px;
}

.registration-footer .copyright {
    font-size: 10px;
}

.registration-form .registration-input-group {
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #cecece;
    border-radius: 7px;
}

.registration-form .ant-form-item {
    margin: 0;
}

.registration-form .registration-checkbox {
    display: flex;
    justify-content: center;
    margin-top: 27px;
}

.registration-form .registration-checkbox .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    line-height: 20px;
}


.registration-form .registration-checkbox .ant-checkbox-wrapper span:nth-of-type(2) {
    font-size: 15px;
}

.registration-form .registration-divider {
    width: 80%;
    min-width: 80%;
    margin: 0 auto;
}

.registration-form .registration-divider-m0 {
    margin: 0;
}

.registration-form label {
    font-size: 17px;
}

.registration-form .forgot-password-actions {
    margin-top: 10px;
    text-align: center;
}

.registration-form .forgot-password-actions a,
p {
    line-height: 30px;
    font-size: 14px;
}

.registration-title {
    font-size: 20px;
    /* font-weight: 500; */
    margin: 0 0 0 0;
    color: black;
}

.anticon-right-circle {
    cursor: pointer;
    color: #8e8e8e;
    transition: color 0.3s;
    font-size: 1.7rem;
}

.anticon-right-circle:hover {
    color: rgb(92, 92, 92);
}

.anticon-right-circle:active {
    color: rgb(92, 92, 92);
}

.ant-form-explain-holder {
    display: none;
}

@media only screen and (max-width: 600px) {

    .registration-title {
        font-size: 20px;
    }

    .registration-form input {
        font-size: 14px;
    }
}

@media only screen and (max-height: 500px) {
    .registration-footer-container {
        display: none;
    }

}

/*Register*/
.register-actions {
    text-align: center;
}

.register-actions > a {
    margin-left: 4px;
}

.register-continue-box {
    margin-top: 27px;
    margin-bottom: 10px;
    text-align: center;
}

.register-continue-button {
    line-height: 1.47059;
    font-weight: 400;
    letter-spacing: -.022em;
    background: #0070c9 linear-gradient(#42a1ec, #0070c9);
    border: 1px solid #07c;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    min-width: 30px;
    padding: 4px 15px;
    text-align: center;
    white-space: nowrap;
}

/* Phone number input field */
.mobile-number-input #phone-form-control {
    width: 100%;
    border: 0;
}

.flag-dropdown {
    border: 0 !important;
    background-color: transparent !important;
}

/* OTP Screen */
.registration-otp-screen-message {
    text-align: center;
    margin-top: 10px;
}

.register-otp-resend {
    text-align: center;
    margin-top: 15px;
}

.otp-phone-number {
    font-weight: bold;
}

.registration-otp-screen-message p {
    line-height: 20px;
}

.registration-form.otp-form {
    margin: 0 auto;
}

/* Password scree */
.registration-password-screen {
    max-width: 400px;
    padding: 1rem;
    width: 100%;
}

/* Registration success screen */
.registration-user-screen {
    text-align: center;
}
.forgot-password-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.forgot-password-error {
    color: #ff4b4b;
    position: absolute;
    text-align: center;
    left: 0;
    width: 100%;
    font-size: 13px;
}

/* .registration-rememberme{
	margin-top: 27px;
} */
.forgot-password-container-main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px 10px;
}

.forgot-password-container-main .logo {
    width: 150px;
    height: 150px;
    padding: 16px;
}

.forgot-password-container-main .logo > img {
    width: 100%;
    border-radius: 50%;
}

.forgot-password-form {
    max-width: 400px;
    padding: 1rem;
    width: 100%;
}

.forgot-password-form .label-small {
    font-size: 10px;
}

.forgot-password-form input {
    font-size: 15px;
    border: none;
    outline: 0;
}

.forgot-password-btn {
    width: 20px;
    cursor: pointer;
    margin-top: 4px;
}

.right-arrow {
    -webkit-filter: opacity(0.6);
            filter: opacity(0.6);
}

.right-arrow:hover {
    -webkit-filter: opacity(0.9);
            filter: opacity(0.9)
}

.ant-input-affix-wrapper:hover {
    border: none;
    outline: none;
}

.forgot-password-form .ant-input:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

.forgot-password-form .forgot-password-form-button {
    width: 100%;
    height: 42px;
    font-size: 18px;
}

.forgot-password-footer {
    width: 100%;
    height: 100px;
    /* position: fixed;
    bottom: 0; */
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 10px 0px 0px 0px;
    flex-direction: column;
}

.forgot-password-footer p {
    margin: 0;
}

.forgot-password-footer a {
    margin-left: 8px;
    margin-right: 8px;
}

.forgot-password-footer .copyright {
    font-size: 10px;
}

.forgot-password-form .forgot-password-input-group {
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #cecece;
    border-radius: 7px;
}

.forgot-password-form .ant-form-item {
    margin: 0;
}

.forgot-password-form .forgot-password-checkbox {
    display: flex;
    justify-content: center;
    margin-top: 27px;
}

.forgot-password-form .forgot-password-divider {
    width: 80%;
    min-width: 80%;
    margin: 0 auto;
}

.forgot-password-form .forgot-password-divider-m0 {
    margin: 0;
}

.forgot-password-form label {
    font-size: 17px;
}

.forgot-password-form .forgot-password-actions {
    margin-top: 10px;
    text-align: center;
}

.forgot-password-form .forgot-password-actions a,
p {
    line-height: 30px;
    font-size: 13px;
}

.forgot-password-title {
    font-size: 20px;
    /* font-weight: 500; */
    margin: 0 0 0 0;
    color: black;
}

.anticon-right-circle {
    cursor: pointer;
    color: #8e8e8e;
    transition: color 0.3s;
    font-size: 1.7rem;
}

.anticon-right-circle:hover {
    color: rgb(92, 92, 92);
}

.anticon-right-circle:active {
    color: rgb(92, 92, 92);
}

.ant-form-explain-holder {
    display: none;
}

@media only screen and (max-width: 600px) {

    .forgot-password-title {
        font-size: 20px;
    }

    .forgot-password-form input {
        font-size: 14px;
    }
}

@media only screen and (max-height: 500px) {
    .forgot-password-footer-container {
        display: none;
    }

}

/* Forgot password Screen */
.forgot-password-screen-message {
    text-align: center;
    margin-top: 10px;
}


.forgot-password-screen-message p {
    line-height: 20px;
}

.forgot-password-continue-box {
    margin-top: 27px;
    margin-bottom: 6px;
    text-align: center;
}

.forgot-password-continue-button {
    line-height: 1.47059;
    font-weight: 400;
    letter-spacing: -.022em;
    background: #0070c9 linear-gradient(#42a1ec, #0070c9);
    border: 1px solid #07c;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    min-width: 30px;
    padding: 4px 15px;
    text-align: center;
    white-space: nowrap;
}

/* Password scree */
.forgot-password-email-screen {
    max-width: 400px;
    padding: 0 1rem 1rem;
    width: 100%;
}

/* forgot-password success screen */
.forgot-password-user-screen {
    text-align: center;
}

.forgot-password-success-email {
    font-weight: bold;
}

.registration-divider {
    margin: 24px 0 10px;
}
.header{
    width: 100%;
    height:50px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    border-bottom: 1px solid #cecece8a;
    justify-content: space-between;
}
.header h4{
    margin: 0;
    -webkit-margin-start: 20px;
            margin-inline-start: 20px;
    -webkit-margin-end: 20px;
            margin-inline-end: 20px;
    font-size: 21px;
    color: black;
}
.header-icon{
    font-size: 20px;
    -webkit-margin-end: 20px;
            margin-inline-end: 20px;
}
.ant-menu{
    border: none;
}
.user-details{
    display: flex;
    flex-flow: column;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 15px;
    margin-top: 10px;
}
.user-details >p{
    margin: 0;
    line-height: 16px;
    font-size: 11px;
}
.user-details >h4{
    margin: 0;
    font-size: 14px;
}
@media only screen and (max-width: 600px){
    .header{
        height: 50px;
    }
    .header h4{
        -webkit-margin-start: 10px;
                margin-inline-start: 10px;
        -webkit-margin-end: 10px;
                margin-inline-end: 10px;
        font-size: 15px;
    }
    .header-icon{
        font-size: 15px;
        -webkit-margin-end: 15px;
                margin-inline-end: 15px;
    }
    .user-details >p{
        font-size: 10px;
    }
}
.cover-image{
    width: 100%;
    max-height: 25vh;
    background-color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.cover-image > img {
    flex-shrink: 0;
    /* min-width: 100%;
    min-height: 100% */
    width: 100%;
}

@media only screen and (max-width:600px){
    .cover-image .ant-empty-image{
        height: 100px;
    }
}


.organization-info{
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;    
    margin-top: -120px;
    box-sizing: border-box;
    padding: 5px 5px 5px 40px;
}
.organization-info .description{
    -webkit-margin-start: 5px;
            margin-inline-start: 5px;
    -webkit-margin-end: 5px;
            margin-inline-end: 5px;
}
.organization-info .description .description-title{
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    color: black;
}
.organization-info .description .description-subtitle{
    font-size: 14px;
    line-height: 16px;
}
.organization-info p{
    margin: 0;
    line-height: 20px;
}
.organization-overview{
    width: 100%;
    box-sizing: border-box;
    padding: 5px 20px 5px 40px;
}
.organization-overview > p{
    font-size: 12px;
    line-height: 17px;
}

.organization-info-avatar{
    min-width: 128px;
    min-height: 128px;
    background-color: white;
    border: solid 2px white;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* .ant-empty{
    width: 100px;
} */
@media only screen and (max-width:600px){
    .organization-info{
        width: 100vw;
        margin-top: -50px;
        /* padding: 5px 5px 5px 20px; */
        flex-direction: column;
        align-items: center;
        padding: 0;
    }
    .organization-info .description{
        text-align: center;
    }
    .organization-info-avatar{
        min-width  : 128px;
        min-height : 128px;
    }
    .organization-info .description > p{
        font-size: 14px;
        line-height: 14px;
    }
    .organization-info .description .description-title{
        font-size: 17px;
        line-height: 25px;
        font-weight: 500;
    }
    .organization-info .description .description-subtitle{
        font-size: 12px;
        line-height: 13px;
    }
    .organization-info .ant-empty-image img {
        /* height: 82px; */
        width: 100%;
    }
    .organization-overview{
        padding: 0 10px;
    }
    .ant-empty-image{
        height: unset;
    }
}
.menu-grid{
    box-sizing: border-box;
    padding: 10px 40px 5px 40px;
}
.menu-grid .col-justify-center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.menu-grid-icon{
    width: 100px;
    height: 100px;
    padding: 20px;
    margin: 5px;
    border: solid 3px #a5a5a5b0;
    border-radius: 50%;
    cursor: pointer;
}
.menu-grid-header{
    display: flex;
}
.menu-grid-header > p{
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: rgb(47, 47, 47);
    margin-bottom: 20px;    
}
.menu-grid-overview-text{
    width: 70%;
    line-height: 16px;
    font-size: 13px;
    text-align: center;
}
.col-justify-center .title{
    font-weight: 500;
    color: black;
}
@media only screen and (max-width:600px){

    .menu-grid{
        padding: 5px 20px 5px 20px;
        margin-top: 10px;   
    }
    .col-justify-center .title{
        font-size: 12px;
    }
    .menu-grid-overview-text{
        font-size: 10px;
    }

}
@media only screen and (max-height:670px){
    .menu-grid-icon{
        width: 75px;
        height: 75px;
        padding: 15px;
        margin: 5px;
    }
}
@media only screen and (max-height:570px){
    .menu-grid-icon{
        width: 50px;
        height: 50px;
        padding: 10px;
        margin: 5px;
    }
}
/* @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
} */

/* Firefox < 16 */
/* @-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
} */

/* Safari, Chrome and Opera > 12.1 */
/* @-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
/* @-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
} */

/* Opera < 12.1 */
/* @-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
} */
.menu-tab{
    box-sizing: border-box;
    padding: 10px 40px 5px 40px;  
    /* transform: translateX(-100%);
    -webkit-transform: translateX(-100%); */
}
.menu-tab-header{
    display: inline-block;
    margin-left: 10px;
}
.menu-tab-header > p{
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    color: black;
    margin-bottom: 20px;  
}

@media only screen and (max-width: 600px){
    .menu-tab{
        padding: 5px 5px 5px 5px;
        margin-top: 10px;   
    }
    .menu-tab-header > p{
        margin-bottom: 20px;
        padding-left:10px;
    }
}

/* .slide-in {
    animation: slide-in 0.3s forwards;
    -webkit-animation: slide-in 0.3s forwards;
} */

/* @keyframes slide-in {
    100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
    100% { -webkit-transform: translateX(0%); }
} */
.menu-back-button{

}

@media only screen and (max-width:600px){

    .menu-back-button{
        padding-left: 10px;
    }

}
.collapse-usage {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

}

.bg-transparent {
    background: transparent;
}

.label-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0px 25px;
    width: 100%;
}

.sub-collapse-panel {
    flex-basis: 100%;
    font-weight: 300;
}

.sub-collapse-panel .group {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.label-wrapper .value {
    padding: 0px 15px;
    font-weight: 500;
    font-size: 12px;
}

.label-wrapper > label {
    font-size: 11px;
}

.sub-collapse-panel .ant-slider {
    flex-grow: 1;
    margin: 14px 10px;
}


.panel-usage {
    background: #f7f7f7;
    border-radius: 4px;
    border: none;
    flex-basis: 45%;
    margin: 10px;
    transition: background-color 2s linear;

}

.panel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.panel-header .title {
    margin: 0;
    line-height: 15px;
}

.panel-header-count {
    font-size: 12px;
    margin: 0;
    padding: 0;
    color: gray;
}

.panel-header > button {
    margin-right: 20px;
}

.panel-header > p {
    margin: 0;
}

.panel-radio-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
}

.panel-radio-group > label {
    -webkit-margin-end: 5px;
            margin-inline-end: 5px;
    -webkit-margin-start: 5px;
            margin-inline-start: 5px;
}

.radio-group-small > label {
    font-size: 12px;
}

.ant-collapse > .ant-collapse-item {
    border: none;
}

.ant-collapse-borderless > .ant-collapse-item {
    border: none;
}

@media only screen and (max-width: 600px) {
    .panel-usage {
        background: #f7f7f7;
        border: none;
        flex-basis: 100%;
        margin: 5px;
    }

    .panel-header > p {
        margin: 0;
        font-size: 13px;
    }

    .panel-radio-group {
        font-size: 12px;
        padding-top: 5px;
    }

    .radio-group-small > label {
        font-size: 11px;
    }
}

/* Extending panel header */
.panel-header-title {
    display: flex;
    margin-bottom: 9px;
    align-items: center;
    cursor: auto;
}

.panel-header-title-name span {
    text-transform: uppercase;
    font-size: 13px;
}

.panel-header-title .panel-header-title-name {
    flex: 1 1;
}

.panel-header-title .panel-header-title-url {
    flex: 1 1;
    display: flex;
    flex-direction: row-reverse;
}

.panel-header-title-url a {
    border: 1px solid #bbb;
    color: #6d6d6d;
    border-radius: 4px;
    text-align: center;
    margin-right: 5px;
    font-size: 12px;
    padding: 4px;
}

.panel-header-title-url a:visited{
    color: #6d6d6d;
}

.panel-header-title-url a:hover{
    color: #000000;
}

.panel-divider {
    height: 1px;
    width: 88%;
    background-color: #ececec;
    margin-bottom: 10px;
}

/*.ant-collapse > .panel-usage > .ant-collapse-header .ant-collapse-arrow {*/
/*    margin-top: 22px;*/
/*}*/

  .timeline-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
    margin: 50px 0px;
  }
  .timeline-container.vertical {
    flex-direction: column;
    min-height: 400px;
  }
  .wrapper {
    display: flex;
    align-items: center;
    flex: 1 1;
  }
  .wrapper.vertical {
    flex-direction: column;
  }
  .wrapper:nth-child(1) {
    flex: unset;
  }
  .circle {
    width: 100%;
    height: 100%;
    background-color: rgb(19, 92, 161);
    border-radius: 50%;
  }
  
  .animate {
    -webkit-animation: scale 1s infinite ease-in-out;
            animation: scale 1s infinite ease-in-out;
  }
  
  .node-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .node-title {
    position: absolute;
    min-width: 150px;
    bottom: 100%;
    padding-bottom: 2px;
    line-height: 18px;
    text-align: center
  }
  .node-title.vertical {
    min-width: unset;
    bottom: unset;
    left: 100%;
    padding-left: 5px;
  }
  .node-title .main-heading{
    font-size: 15px;
    font-weight: 500;
  }
  .node-title .subtitle{
    font-size: 10px;
  }
  .node {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: solid 2px rgb(19, 92, 161);
    padding: 3px;
    box-sizing: border-box;
    position: relative;
  }
  
  .line {
    border: none;
    margin: 0px 5px;
    flex-grow: 1;
  }
  .line.vertical {
    margin: 5px 0px;
  }
  .line-solid {
    border-top: 2px solid rgb(19, 92, 161);
  }
  .line-dotted {
    border-top: 2px dotted rgb(19, 92, 161);
  }
  .line-solid.vertical {
    border-left: 2px solid rgb(19, 92, 161);
  }
  .line-dotted.vertical {
    border-left: 2px dotted rgb(19, 92, 161);
  }
  
  @-webkit-keyframes scale {
    0% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
  }
  
  @keyframes scale {
    0% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
  }
  
.router-content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.router-content-container .router-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}
.subscribe-org-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}
.login-error{
    color: #ff4b4b;
    position: absolute;
    text-align: center;
    left: 0;
    width: 100%;
    font-size: 13px;
}
/* .login-rememberme{
	margin-top: 27px;
} */
.subscribe-org-container-main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px 10px;
}
.subscribe-org-container-main .logo{
    width: 150px;
    height: 150px;
    padding: 16px;
}
.subscribe-org-container-main .logo > img {
    width: 100%;
    border-radius: 50%;
}
.login-form {
    max-width: 400px;
    padding: 1rem;
    width: 100%;
}
.login-form .label-small{
    font-size : 10px;
}
.login-form input {
    font-size: 15px;
    border: none;
    outline: 0;
}
.login-btn{
    width: 20px;
    cursor: pointer;
    margin-top: 4px;
}
.right-arrow{
    -webkit-filter:opacity(0.6);
            filter:opacity(0.6);
}
.right-arrow:hover{
    -webkit-filter: opacity(0.9);
            filter: opacity(0.9)
}
.ant-input-affix-wrapper:hover{
    border: none;
    outline: none ;
}

.login-form .ant-input:focus{
    border: none;
    outline: none;
    box-shadow: none;
}

.login-form .login-form-button {
    width: 100%;
    height: 42px;
    font-size: 18px;
}
.login-footer {
    width: 100%;
    height: 100px;
    /* position: fixed;
    bottom: 0; */
    display: flex;
    align-items: center;
    justify-content:center;
    box-sizing: border-box;
    padding: 10px 0px 0px 0px;
    flex-direction: column;
}
.login-footer p{
    margin: 0;
}
.login-footer a{
    margin-left: 8px;
    margin-right: 8px;
}
.login-footer .copyright{
    font-size: 10px;
}
.login-form .login-input-group{
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #cecece;
    border-radius: 7px;
}
.login-form .ant-form-item{
    margin: 0;
}
.login-form .login-checkbox {
    display: flex;
    justify-content: center;
    margin-top: 27px;
}
.login-form .login-divider {
    width: 80%;
    min-width: 80%;
    margin: 0 auto;
}
.login-form .login-divider-m0{
    margin: 0;
}
.login-form label {
    font-size: 17px;
}
.login-form .login-actions {
    margin-top: 10px;
    text-align: center;
}
.login-form .login-actions a,
p {
    line-height: 30px;
    font-size: 14px;
}
.login-title {
    font-size: 20px;
    /* font-weight: 500; */
    margin: 0 0 0 0;
    color: black;
}
.anticon-right-circle {
    cursor: pointer;
    color: #8e8e8e;
    transition: color 0.3s;
    font-size: 1.7rem;
}
.anticon-right-circle:hover {
    color: rgb(92, 92, 92);
}
.anticon-right-circle:active {
    color: rgb(92, 92, 92);
}
.ant-form-explain-holder{
    display: none;
}
@media only screen and (max-width: 600px) {

    .login-title {
        font-size: 20px;
    }
    .login-form input {
        font-size: 14px;
    }
}
@media only screen and (max-height: 500px) {
    .login-footer-container{
        display: none;
    }

}

/*Register actions*/
.register-actions {
    text-align: center;
}
.register-actions>a {
    margin-left: 4px;
}

.subscribe-org-content-header {
    margin-top: 15px;
    text-align: center;
}

.subscribe-org-content-header p {
    line-height: 15px;
    font-size: 12px;
}

.dashboard-footer{
	height: 60px;
	bottom: 0;
	display: flex;
	justify-content:flex-end;
	box-sizing: border-box;
    padding: 5px;
	flex-direction: column;
	right: 0;
	width: 100%;
}
.dashboard-spin{
	display: flex;
	width: 100%;
	box-sizing: border-box;
	padding: 10px;
	justify-content: center;
}
.dashboard-error{
	display: flex;
    justify-content: center;
    padding: 10px;
}
@media only screen and (max-height:570px){
    .dashboard-footer{
		position: inherit;
	}
}
/*CSS*/
html { scroll-behavior: smooth; }

.page-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-container {
    width: 600px;
}

.content-container .logo {
    text-align: center;
    width: 150px;
    height: 150px;
    padding: 16px;
}

.content-container .logo img {
    width: 100%;
    border-radius: 50%;
}

.privacy-content {
    line-height: 21px;
    text-align: justify;
}

.logo-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}

.privacy-policy-text {
    margin-top: 40px;
    padding: 15px;
}
