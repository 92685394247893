.registration-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.registration-error {
    color: #ff4b4b;
    position: absolute;
    text-align: center;
    left: 0;
    width: 100%;
    font-size: 13px;
}

/* .registration-rememberme{
	margin-top: 27px;
} */
.registration-container-main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px 10px;
}

.registration-container-main .logo {
    width: 150px;
    height: 150px;
    padding: 16px;
}

.registration-container-main .logo > img {
    width: 100%;
    border-radius: 50%;
}

.registration-form {
    max-width: 400px;
    padding: 1rem;
    width: 100%;
}

.registration-form .label-small {
    font-size: 10px;
}

.registration-form input {
    font-size: 15px;
    border: none;
    outline: 0;
}

.registration-btn {
    width: 20px;
    cursor: pointer;
    margin-top: 4px;
}

.right-arrow {
    filter: opacity(0.6);
}

.right-arrow:hover {
    filter: opacity(0.9)
}

.ant-input-affix-wrapper:hover {
    border: none;
    outline: none;
}

.registration-form .ant-input:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

.registration-form .registration-form-button {
    width: 100%;
    height: 42px;
    font-size: 18px;
}

.registration-footer {
    width: 100%;
    height: 100px;
    /* position: fixed;
    bottom: 0; */
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 10px 0px 0px 0px;
    flex-direction: column;
}

.registration-footer p {
    margin: 0;
}

.registration-footer a {
    margin-left: 8px;
    margin-right: 8px;
}

.registration-footer .copyright {
    font-size: 10px;
}

.registration-form .registration-input-group {
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #cecece;
    border-radius: 7px;
}

.registration-form .ant-form-item {
    margin: 0;
}

.registration-form .registration-checkbox {
    display: flex;
    justify-content: center;
    margin-top: 27px;
}

.registration-form .registration-checkbox .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    line-height: 20px;
}


.registration-form .registration-checkbox .ant-checkbox-wrapper span:nth-of-type(2) {
    font-size: 15px;
}

.registration-form .registration-divider {
    width: 80%;
    min-width: 80%;
    margin: 0 auto;
}

.registration-form .registration-divider-m0 {
    margin: 0;
}

.registration-form label {
    font-size: 17px;
}

.registration-form .forgot-password-actions {
    margin-top: 10px;
    text-align: center;
}

.registration-form .forgot-password-actions a,
p {
    line-height: 30px;
    font-size: 14px;
}

.registration-title {
    font-size: 20px;
    /* font-weight: 500; */
    margin: 0 0 0 0;
    color: black;
}

.anticon-right-circle {
    cursor: pointer;
    color: #8e8e8e;
    transition: color 0.3s;
    font-size: 1.7rem;
}

.anticon-right-circle:hover {
    color: rgb(92, 92, 92);
}

.anticon-right-circle:active {
    color: rgb(92, 92, 92);
}

.ant-form-explain-holder {
    display: none;
}

@media only screen and (max-width: 600px) {

    .registration-title {
        font-size: 20px;
    }

    .registration-form input {
        font-size: 14px;
    }
}

@media only screen and (max-height: 500px) {
    .registration-footer-container {
        display: none;
    }

}

/*Register*/
.register-actions {
    text-align: center;
}

.register-actions > a {
    margin-left: 4px;
}

.register-continue-box {
    margin-top: 27px;
    margin-bottom: 10px;
    text-align: center;
}

.register-continue-button {
    line-height: 1.47059;
    font-weight: 400;
    letter-spacing: -.022em;
    background: #0070c9 linear-gradient(#42a1ec, #0070c9);
    border: 1px solid #07c;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    min-width: 30px;
    padding: 4px 15px;
    text-align: center;
    white-space: nowrap;
}

/* Phone number input field */
.mobile-number-input #phone-form-control {
    width: 100%;
    border: 0;
}

.flag-dropdown {
    border: 0 !important;
    background-color: transparent !important;
}

/* OTP Screen */
.registration-otp-screen-message {
    text-align: center;
    margin-top: 10px;
}

.register-otp-resend {
    text-align: center;
    margin-top: 15px;
}

.otp-phone-number {
    font-weight: bold;
}

.registration-otp-screen-message p {
    line-height: 20px;
}

.registration-form.otp-form {
    margin: 0 auto;
}

/* Password scree */
.registration-password-screen {
    max-width: 400px;
    padding: 1rem;
    width: 100%;
}

/* Registration success screen */
.registration-user-screen {
    text-align: center;
}