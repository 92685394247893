.menu-grid{
    box-sizing: border-box;
    padding: 10px 40px 5px 40px;
}
.menu-grid .col-justify-center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.menu-grid-icon{
    width: 100px;
    height: 100px;
    padding: 20px;
    margin: 5px;
    border: solid 3px #a5a5a5b0;
    border-radius: 50%;
    cursor: pointer;
}
.menu-grid-header{
    display: flex;
}
.menu-grid-header > p{
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: rgb(47, 47, 47);
    margin-bottom: 20px;    
}
.menu-grid-overview-text{
    width: 70%;
    line-height: 16px;
    font-size: 13px;
    text-align: center;
}
.col-justify-center .title{
    font-weight: 500;
    color: black;
}
@media only screen and (max-width:600px){

    .menu-grid{
        padding: 5px 20px 5px 20px;
        margin-top: 10px;   
    }
    .col-justify-center .title{
        font-size: 12px;
    }
    .menu-grid-overview-text{
        font-size: 10px;
    }

}
@media only screen and (max-height:670px){
    .menu-grid-icon{
        width: 75px;
        height: 75px;
        padding: 15px;
        margin: 5px;
    }
}
@media only screen and (max-height:570px){
    .menu-grid-icon{
        width: 50px;
        height: 50px;
        padding: 10px;
        margin: 5px;
    }
}
/* @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
} */

/* Firefox < 16 */
/* @-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
} */

/* Safari, Chrome and Opera > 12.1 */
/* @-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
/* @-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
} */

/* Opera < 12.1 */
/* @-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
} */