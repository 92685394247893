.cover-image{
    width: 100%;
    max-height: 25vh;
    background-color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.cover-image > img {
    flex-shrink: 0;
    /* min-width: 100%;
    min-height: 100% */
    width: 100%;
}

@media only screen and (max-width:600px){
    .cover-image .ant-empty-image{
        height: 100px;
    }
}