.menu-back-button{

}

@media only screen and (max-width:600px){

    .menu-back-button{
        padding-left: 10px;
    }

}