.openInAppSliver {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 10000;
    color: white !important;
    text-decoration: none;
    background-color: #1a8ffd;
    height: 6vh;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    /* This timing applies on the way IN */
    transition-timing-function: ease-out;

    /* A litttttle slower on the way in */
    transition: 0.25s;

    /* Move into place */
    transform: translateY(0);
}

.openInAppSliver a {
    color: #ffffff;
}

.openInAppSliver a:visited {
    color: #ffffff;
    font-weight: bold;
}

.appLinkContainer {
    position: relative;
    width: 100%;
    text-align: center;
}

.close {
    position: absolute;
    right: 32px;
    width: 32px;
    height: 32px;
    opacity: 1;
    color: #fff;
}
.close:hover {
    opacity: 1;
}
.close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 15px;
    width: 2px;
    background-color: #fff;
}
.close:before {
    transform: rotate(45deg);
}
.close:after {
    transform: rotate(-45deg);
}
