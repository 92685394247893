/*CSS*/
html { scroll-behavior: smooth; }

.page-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-container {
    width: 600px;
}

.content-container .logo {
    text-align: center;
    width: 150px;
    height: 150px;
    padding: 16px;
}

.content-container .logo img {
    width: 100%;
    border-radius: 50%;
}

.privacy-content {
    line-height: 21px;
    text-align: justify;
}

.logo-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}

.privacy-policy-text {
    margin-top: 40px;
    padding: 15px;
}